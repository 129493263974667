import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import type { DashboardFilters } from '~/composables/useAPI/useApiDashboard'

export const useApiDashboardFilter = (
  companyId: string,
  filter: 'state' | 'sector' | 'scope',
  filters: Ref<DashboardFilters>,
) => {
  const _filters = computed(() => {
    const _filters = { ...filters.value }
    delete _filters[filter]
    return _filters
  })
  return useQuery({
    queryKey: [
      'companies',
      companyId,
      'dashboard',
      'filters',
      filter,
      _filters,
    ],
    queryFn: ({ signal }) => {
      return fetchAPI<string[]>(
        `/companies/${companyId}/dashboard/filters/${filter}`,
        {
          method: 'POST',
          body: _filters.value,
          signal,
        },
      )
    },
    staleTime: 0,
    placeholderData: keepPreviousData,
  })
}
